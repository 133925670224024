import { useMenus, Link } from "docz";
import { Container, Row, Col, Card, Button } from "react-bootstrap";
import "../../../../docs/style-index.css";
import * as React from 'react';
export default {
  useMenus,
  Link,
  Container,
  Row,
  Col,
  Card,
  Button,
  React
};