import React from "react"

const Image = ({ src, alt }) => {
  return (
    <div className="container p-0" style={{ margin: "2rem 0" }}>
      <img
        className="img-fluid mx-auto d-block"
        style={{ border: "1px solid #CED4DE", maxWidth: "850px" }}
        src={src}
        alt={alt}
      />
    </div>
  )
}

export default Image
